import React, { useEffect, useState } from 'react';
import Quagga from 'quagga';
import MobileDetect from 'mobile-detect';
import 'webrtc-adapter';
import { BrowserQRCodeReader } from '@zxing/library';
import { useValueReader } from '../ValueReader';
import './Camera.css';

const initState = {
  inputStream: {
    name: "Live",
    type: "LiveStream",
    target: document.querySelector('#camera'),
    constraints: {
      // width: {min: 640},
      // height: {min: 480},
      facingMode: "environment",
      aspectRatio: { min: 2, max: 3 },
    }
  },
  locator: {
    patchSize: "medium",
    halfSample: true
  },
  // numOfWorkers: 2,
  frequency: 10,
  decoder: {
    readers: ["code_128_reader",
              "ean_reader",
              "ean_8_reader",
              "code_39_reader",
              "code_39_vin_reader",
              "codabar_reader",
              "upc_reader",
              "upc_e_reader",
              "i2of5_reader",
              "2of5_reader",
              "code_93_reader"]
  },
  locate: true,
  lastResult: null,
  multiple: true,
  drawBoundingBox: true
}

let prevState = false;
let codeReader;

export default function Camera({ OnDetected, start, contentRef, error, loading }) {
  const [torch, setTorch] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const { readValue } = useValueReader();

  useEffect(() => {
    if (prevState === start || start === undefined)
      return;

    loading = true;
    if (start) {
      init();
    } else {
      stop();
    }

  }, [start]);

  const init = () => {
    if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {

      codeReader = new BrowserQRCodeReader();
      codeReader
        .decodeFromInputVideoDevice(undefined, 'video')
        .then(result => onQrDetected(result))
        .catch(err => handleError(err));

      const { width, height } = initState.inputStream.constraints;
      fixOrientation();
      initState.inputStream.target = document.querySelector('#camera');

      Quagga.onDetected(onDetected);
      Quagga.init(initState, err => {
        if (err) handleError(err);

        checkCapabilities();
        Quagga.start();
        prevState = true;
        setShowCamera(true);
        loading = false;
      })
    }
  }

  const handleError = err => {
    loading = false;
    error(err);
  }

  const checkCapabilities = () => {
    var streamLabel = Quagga.CameraAccess.getActiveStreamLabel();
    let track = Quagga.CameraAccess.getActiveTrack();
    let capabilities = {};

    if (typeof track.getCapabilities === 'function') {
      capabilities = track.getCapabilities();
      setTorch(capabilities.torch);
      initState.inputStream.constraints.deviceId = capabilities.deviceId || capabilities.id;
    }
  }

  const fixOrientation = () => {
    var md = new MobileDetect(window.navigator.userAgent)

    if (md.phone() || md.tablet()) {
      if (window.matchMedia('(orientation:portrait)').matches) {
        if (md.userAgent() !== 'Safari') {
          initState.inputStream.constraints.aspectRatio = { min: 1, max: 1 };
        }
      }
    }
  }

  const stop = () => {
    codeReader.reset();
    Quagga.offDetected(onDetected);
    Quagga.stop();
    loading = false;
    setShowCamera(false);
    prevState = false;
  }

  const onQrDetected = (result) => { //v typescriptu staci implementovat rozhrani a neni treba delat dve metody
    readValue(result.text)
      .then(code => OnDetected({code}));    
  }

  const onDetected = (result) => {
    readValue(result.codeResult.code)
      .then(code => OnDetected({code}));
  }

  const cameraContainer = `${!!showCamera ? "" : "hideCameraContainer"}`;

  return (
    <div>
      <div className={cameraContainer}>
        <div ref={contentRef} id="camera" className="interactive">
          <video id="video" src=""></video>
          <canvas className="drawingBuffer"></canvas>
        </div>
      </div>
    </div>
  )
}