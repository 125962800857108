import React, { useEffect, useRef } from 'react';
import { Items } from './Items';
import usePage from 'components/Page/usePage';
import IntlMessages from 'util/IntlMessages';
import QuickPinchZoom, {
    make2dTransformValue,
    make3dTransformValue,
    hasTranslate3DSupport
  } from "react-quick-pinch-zoom";

const Summary = (props) => {
    const gridRef = useRef();
    const {
        setPageTitle, 
        clearPageTitle,
        addIconButtons,
        clearIconButtons,
        addCustom
    } = usePage();

    useEffect(() => {
        // setPageTitle(<IntlMessages id="pages.stockout.title" />);
        return () => {
            clearPageTitle();
            clearIconButtons();
        }
    }, []);
    
    const handleUpdate = ({x, y, scale}) => {
        const {current: Items} = gridRef;
        if (!Items == undefined) {
            const value = make3dTransformValue({ x, y, scale });
            Items.style.setProperty("transform", value);    
        }
    }

    return (
        // <QuickPinchZoom {...props} onUpdate={handleUpdate}>
        //     <div ref={gridRef} style={{display: 'block', width: '800px', height: '400px', backgroundColor: 'black', textAlign: 'center', color: 'white', verticalAlign: 'center', fontSize: 60}}>
        //         ;ewklfjqwe;kfj    
        //     </div>
        // </QuickPinchZoom>

        <React.Fragment>
            <Items {...props} />
        </React.Fragment>

    )
}

export default Summary;