import React, {useContext} from 'react';
import {usePageContext} from 'components/Page/usePage';

export default function HeaderTitle() {
  const {state} = usePageContext();
  const {title} = state;
  return (
    <div>
      <div className="header-menu">
        <h3 className="title">{title}</h3>
      </div>
    </div>
  );
}
