import React from 'react';

export default function MathCalculation(gridApi) {
  var range = gridApi.rangeController.cellRanges;
  var length = 0, sum = 0, min = null, max = null, avg = 0, count = 0, rowVolume = 1, volume2d = 0, volume3d = 0;
  
  range.map(item => {
    console.log("item", item.startRow, item.endRow, item);
      const start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex);
      const end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex);
      for (var rowIndex = start; rowIndex <= end; rowIndex++) {
        const dimension = 0;
        rowVolume = 1;

        item.columns.forEach(column => {
              var rowModel = gridApi.getModel();
              var rowNode = rowModel.getRow(rowIndex);
              var value = gridApi.getValue(column, rowNode);
              if (typeof value === "number") {
                  dimension++;
                  sum += value;
                  min = Math.min(min || value, value);
                  max = Math.max(max || value, value);
                  count ++;

                  if (dimension <= 3) {
                    rowVolume = rowVolume * value;
                  } else {
                    rowVolume = 0;
                  }
              }
          })
          if (dimension === 2) {
            volume2d = volume2d + rowVolume; 
          } else if (dimension === 3) {
            volume3d = volume3d + rowVolume;
          }

      }
      length += (Math.abs(item.startRow.rowIndex - item.endRow.rowIndex) + 1) * item.columns.length;
      avg = (sum / count);
  });

  return {
    length,
    sum,
    min,
    max,
    avg,
    count,
    volume2d,
    volume3d
  };
}