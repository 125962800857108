import * as React from "react";

export const init = {
  beep: true,
  timeToEvaluate: 50,
  averageWaitTime: 50,
  startCharacter: [],
  endCharacter: [13, 27],
  onComplete: () => {},
  onError: () => {},
  minLength: 13,
  ignoreIfFocusOn: false,
  stopPropagation: false,
  preventDefault: false,
  container: document,
  bitlyCode: false
};


export const ScannerContext = React.createContext(init);
export const useScannerContext = () => React.useContext(ScannerContext);
