
export default function useKeyboard() {
  const checker = '+ěščřžýáíé';
  const fail = '+ěščřžýáíéyz-"';
  const right = '1234567890zy/:'

  const CheckKeyboard = value => {
    let result = value;
    let isFail = false;

    for(let i = 0; i < value.length; i++) {
      let index = checker.indexOf(value.charAt(i));
      if (index > -1) {
        isFail = true;
        break;
      }
    }
    if (value.indexOf("bit.lz") > -1) {
      isFail = true;
    }
    
    if (isFail) {
      result = '';
      for(let c = 0; c < value.length; c++) {
        let index = fail.indexOf(value.charAt(c));
        if (index > -1) {
          result = result + right.charAt(index); 
        } else {
          result = result + value.charAt(c);
        }
      }  
    }
    return result;
  }

  return {
    CheckKeyboard
  };
}