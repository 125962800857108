import React, {useContext} from 'react';

export const initialPageContext = {
  title: '',
  subtitle: '',
  buttons: [],
  activeButton: null,
  iconButtons: [],
  custom: [],
  url: ''
}

const PageContext = React.createContext(initialPageContext);
export default PageContext;

export const usePageContext = () => {
  return useContext(PageContext);
}
