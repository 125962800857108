"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apis = void 0;
var headers = {
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT"
};
var bitLy = {
  url: "/api/bitly/bitly",
  methodType: "POST",
  headers: headers,
  version: "1.0"
};
var apis = {
  bitLy: bitLy
};
exports.apis = apis;

