"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useKeyboard;

var _startsWith = _interopRequireDefault(require("lodash/startsWith"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useKeyboard() {
  var checker = '+ěščřžýáíé';
  var fail = '+ěščřžýáíéyz-"';
  var right = '1234567890zy/:';

  var checkKeyboard = function checkKeyboard(value) {
    var isFail = false;
    value = value.replaceAll("Shift", ""); // nektere ctecky pro velke pismeno vypise, ze zmackla klavesu shift

    value = value.replaceAll("Unidentified", "");
    var result = value;

    for (var i = 0; i < value.length; i++) {
      var index = checker.indexOf(value.charAt(i));

      if (index > -1) {
        isFail = true;
        break;
      }
    }

    if (value.indexOf("bit.lz") > -1) {
      isFail = true;
    }

    if (isFail) {
      result = '';

      for (var c = 0; c < value.length; c++) {
        var _index = fail.indexOf(value.charAt(c));

        if (_index > -1) {
          result = result + right.charAt(_index);
        } else {
          result = result + value.charAt(c);
        }
      }
    }

    return result;
  };

  var validBarcode = function validBarcode(code) {
    var len = (code || '').length;
    if (len < 7 || len > 9) return false;
    if (!(0, _startsWith.default)(code, "2")) return false;
    return true;
  };

  return {
    checkKeyboard: checkKeyboard,
    validBarcode: validBarcode
  };
}

