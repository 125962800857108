import * as React from "react";
import { ScannerContext, init } from "./Context";

export const ScannerContainer = ({ children, config }) => {
  const [valueContext] = React.useState({ ...init, ...config });

  return (
    <ScannerContext.Provider value={valueContext}>
      {children}
    </ScannerContext.Provider>
  );
};
