import { useContext } from 'react';
import PageContext from './redux/PageContext';
import { 
  pageTitle, 
  addIconButtons as AddIconButtons, 
  clearIconButtons as ClearIconButtons,
  addCustom,
  clearCustom,
  setUrl
} from './redux/PageAction';

export const usePageContext = () => {
  return useContext(PageContext);
}

function usePage(match) {
  const { state, dispatch } = usePageContext();

  if (!!match) {
    if (state.url != match.url) {
      dispatch(setUrl(match.url));
    }
  }

  const setPageTitle = title => dispatch(pageTitle(title));
  const clearPageTitle = () => dispatch(pageTitle(''));
  const addIconButtons = buttons => dispatch(AddIconButtons(buttons));
  const clearIconButtons = () => dispatch(ClearIconButtons());
  const addCustomDispatch = custom => dispatch(addCustom(custom));
  const clearCustomDispatch = () => dispatch(clearCustom());

  return {
    setPageTitle,
    clearPageTitle,
    addIconButtons,
    clearIconButtons,
    addCustom: addCustomDispatch,
    clearCustom: clearCustomDispatch
  };
}

export default usePage;