import * as React from "react";
import uuidv4 from "uuid/v4";
import { useChannel, useEvent } from "@harelpls/use-pusher";

const uncapitalizeKeys = (obj) => {
  const isObject = o => Object.prototype.toString.apply(o) === '[object Object]'
  const isArray = o => Object.prototype.toString.apply(o) === '[object Array]'

  let transformedObj = isArray(obj) ? [] : {}

  for (let key in obj) {
    // replace the following with any transform function
    const transformedKey = key.replace(/^\w/, (c, _) => c.toLowerCase())

    if (isObject(obj[key]) || isArray(obj[key])) {
      transformedObj[transformedKey] = uncapitalizeKeys(obj[key])
    } else {
      transformedObj[transformedKey] = obj[key]
    }
  }
  return transformedObj
}

const Pusher = ({ channelName, eventName, onEvent, children }) => {
  const _key = uuidv4();
  const channel = useChannel(channelName);

  useEvent(channel, eventName, (data) => {
    const uncapitalizedMessage = uncapitalizeKeys(data);
    onEvent(data);
  });

  return null;
};

export default Pusher;
