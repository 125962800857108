import React, { useContext, useReducer } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import PageContext, { usePageContext } from "components/Page/redux/PageContext";
import SummaryContext, {
  useSummaryContext,
} from "./app/routes/StockOut/routes/redux/summaryContext";
import PageReducer from "components/Page/redux/PageReducer";
import SummaryReducer, {
  initSummary,
} from "app/routes/StockOut/routes/redux/summaryReducer";
import { ScannerContainer } from "@bit/hlouzek.rhplus.barcodescanner/index";

import configureStore, { history } from "./store";
import App from "./containers/App";

// import {LicenseManager} from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
// LicenseManager.setLicenseKey("MTU1MzczMTIwMDAwMA==555b6946f1f4ee082a509e13fbc32c10");

export const store = configureStore();

const Apps = (props) => {
  const initialPageContext = usePageContext();
  const [state, dispatch] = useReducer(PageReducer, initialPageContext);
  const [summaryState = state, summaryDispatch = dispatch] = useReducer(
    SummaryReducer,
    initSummary
  );
  return (
    <SummaryContext.Provider value={{ summaryState, summaryDispatch }}>
      <PageContext.Provider value={{ state, dispatch }}>
        <App {...props} />
      </PageContext.Provider>
    </SummaryContext.Provider>
  );
};

export default function MainApp() {
  return (
    <ScannerContainer config={{ minLength: 5, bitlyCode: true }}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={Apps} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    </ScannerContainer>
  );
}
