import * as React from 'react';
import { PusherProvider } from "@harelpls/use-pusher";

const Provider = ({config, children}) => {

  return (
    <PusherProvider {...config}>
      {children}
    </PusherProvider>
  )
}

export default Provider;