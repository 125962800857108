import { toast } from 'react-toastify';
import {ICallApi} from '../Api';

export const errorCatch = (error:any):void => {
  console.log(error.response);
  toast.error("API error, see console for details.");
}

export const successMessage = (message:string):void => {
  toast.success(message);
}

// prepsat na bitly.com -> vyresit CORS a presmerovani adresy
const bitLy: ICallApi = {
  url: "bitly/bitly",
  methodType: "POST"
}

export const apis = {
  bitLy,
}
