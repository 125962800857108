import React, { useState, useEffect, useRef } from "react";

// Globální keš pro obrázky
const imageCache = {};

// ImageRenderer komponenta
const ImageRenderer = React.memo(({ value }) => {
  const [imageUrl, setImageUrl] = useState('');
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    if (value && value.length > 0) {
      const key = value[0].key;

      if (imageCache[key]) {
        // Pokud je obrázek v keši, použijeme ho
        setImageUrl(imageCache[key]);
      } else {
        // Pokud není obrázek v keši, načteme ho a uložíme do keše
        const url = `/api/filestorage/thumbnailsrc/${key}`;

        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            if (isMounted.current) {
              const imageObjectUrl = URL.createObjectURL(blob);
              imageCache[key] = imageObjectUrl;
              setImageUrl(imageObjectUrl);
            }
          })
          .catch(() => {
            if (isMounted.current) {
              setImageUrl(''); // Chybová URL nebo prázdná hodnota
            }
          });
      }
    } else {
      setImageUrl(''); // Resetovat URL pokud není hodnota
    }

    return () => {
      isMounted.current = false;
    };
  }, [value]);

  if (!imageUrl) return <img />;

  return (
    <span className="imgSpanLogo">
      <img
        alt="Flag"
        src={imageUrl}
        className="logo"
      />
    </span>
  );
});

export default ImageRenderer;