import * as React from 'react';

export const clientLogo = (clientName) => {
  switch ((clientName || '').toLowerCase()) {
    case "tk maxx":
      return {
        name: 'TKM',
        color: '#ff1b32'
      }
    case "primark":
      return {
        name: "PRI",
        color: '#02addb'
      }
    default:
      return {
        name: clientName && clientName.substring(0, 3).toUpperCase(),
        color: 'black'
      }
  }
}


export const customFilter = (filterText, params, search) => {
  // const { clientName, storeNo, firstProjectName } = params.data;
  // const client_storeNo = storeNo && `(${storeNo})`;
  // const { name } = clientLogo(clientName);

  // const combinedString = `${name} ${firstProjectName} ${client_storeNo}`.toLowerCase();
  // return combinedString.includes(filterText.toLowerCase());
  return true;
};


const ProjectRenderer = (props) => {

  const { clientName, storeNo, firstProjectName } = props.data;
  const { name, color } = clientLogo(clientName);
  const client_storeNo = storeNo && `(${storeNo})`;

  return (
    <div>
      <span style={{ color: color }}>{name} </span>
      {firstProjectName} {client_storeNo}
    </div>
  );
};

export default ProjectRenderer;
