import React from 'react';
import { usePageContext } from 'components/Page/redux/PageContext';
import IconButton from '@material-ui/core/IconButton';

export default function IconButtons() {
  const { state: { iconButtons } } = usePageContext();

  return (
    <>
      {
        iconButtons.map(iconButton => {
          const {key, className} = iconButton;
          const liClassName = `list-inline-item ${key}`;
          return (
            <li className={liClassName} key={iconButton}>
              <div className="quick-menu">
                <IconButton id={key} className="icon-btn">
                  <i className={className} />
                </IconButton>
              </div>
            </li>
          )
        })
      }
    </>
  )
}

