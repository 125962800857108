import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';

export default {
  mixins: {
    toolbar: {
      minHeight: 40
    }
  },
  overrides: {
    // MuiFormControl: {
    //   root: {
    //     paddingBottom: 15
    //   }
    // },
    // MuiFormLabel: {
    //   root: {
    //     color: '#8F91A4'
    //   }
    // },
    // MuiInput: {
    //   input: {
    //     padding: 10,
    //     border: 'none',
    //     backgroundColor: 'rgb(235, 237, 244)',
    //     fontWeight: 500,
    //     fontSize: '14px',
    //     color: 'rgb(39, 52, 119)',        
    //     outline: 'rgb(205, 210, 227) solid 2px',
    //     "&:focus": {
    //       outlineOffset: '0px',
    //       outline: 'rgb(205, 210, 227) solid 2px'
    //     }
    //   }
    // }
  },
  // props: {
  //   MuiInput: {
  //     disableUnderline: true,
  //   }
  // },
  palette: {
    primary: {
      light: green[300],
      main: green[500],
      dark: green[700],
      contrastText: '#fff'
    },
    secondary: {
      light: orange[300],
      main: orange['A700'],
      dark: orange[700],
      contrastText: '#fff'
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: [
      "Graphik"
    ].join(','),
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
  },
};
