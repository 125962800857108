import useScanDetection from "use-scan-detection";
import { useScannerContext } from "./Context";
import useValueReader from "@bit/hlouzek.rhplus.value-reader";
import useKeyboard from "@bit/hlouzek.rhplus.keyboard";
import useBeeper from "@bit/hlouzek.rhplus.beeper/Beeper";

const UseScannerDetection = ({ onError, onComplete, config }) => {
  const init = useScannerContext();
  const { readValue } = useValueReader();
  const { checkKeyboard } = useKeyboard();
  const { beep } = useBeeper();

  
  const handleComplete = (input) => {
    // if (configuration.beep) beep();
    if (configuration.bitlyCode) {
      readValue(input)
        .then(e => {
          if (onComplete !== undefined) onComplete(e);
          else config && config.onComplete(e);  
        })
        .catch(error => {
          if (onError !== undefined) onError(error);
          else config && config.onError(error);
        })
    } else {
      const e = checkKeyboard(input);
      if (onComplete !== undefined) onComplete(e);
      else config && config.onComplete(e);    
    }
  };

  const configuration = {
    ...init,
    ...config,
    onComplete: handleComplete,
  };

  useScanDetection(configuration);
};

export default UseScannerDetection;
