
import { 
  PAGE_TITLE, 
  PAGE_SUBTITLE,
  PAGE_ADD_GROUP_BUTTONS,
  PAGE_ACTIVE_GROUP_BUTTON, 
  PAGE_CLEAR_PAGE,
  PAGE_ADD_ICON_BUTTONS,
  CLEAR_ICON_BUTTONS,
  PAGE_ADD_CUSTOM,
  PAGE_CLEAR_CUSTOM,
  PAGE_SET_URL
} from "./PageConstants";
import {initialPageContext} from './PageContext';

const page = (state = initialPageContext, action) => {
  switch (action.type) {
    case PAGE_TITLE: 
      return {
        ...state,
        title: action.title
    }

    case PAGE_SUBTITLE: 
    return {
      ...state,
      subtitle: action.subtitle
  }

    case PAGE_ADD_GROUP_BUTTONS:
      return {
        ...state,
        buttons: action.buttons
      }

    case PAGE_ACTIVE_GROUP_BUTTON:
      return {
        ...state,
        activeButton: action.activeButton
      }

    case PAGE_ADD_ICON_BUTTONS:
      return {
        ...state,
        iconButtons: action.iconButtons
      }

    case PAGE_CLEAR_PAGE:
      return {
        ...initialPageContext
      }

    case CLEAR_ICON_BUTTONS:
      return{
        ...state,
        iconButtons: []
      }

    case PAGE_ADD_CUSTOM:
      return {
        ...state,
        custom: action.custom
      }

    case PAGE_CLEAR_CUSTOM:
      return {
        ...state,
        custom: []
      }

    case PAGE_SET_URL:
      return {
        ...state,
        url: action.url
      }

    default:
      return state;
  }
}

export default page;