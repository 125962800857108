import React from 'react';

class HeaderSubtitle extends React.Component {

  render() {
    const {subtitle} = this.props;
    return (
      <div>
      { subtitle && subtitle.length > 0 ? (
          <div className="header-menu d-inline-block ml-2 mr-3">
            <div className="subtitle">{subtitle}</div>
          </div>  
        ) : (
          <div />
        )
      }
      </div>
    );
  }
}

HeaderSubtitle.defaultProps = {
  subtitle: ''
}

export default HeaderSubtitle;