import { 
  PAGE_TITLE,
  PAGE_SUBTITLE,
  PAGE_ADD_GROUP_BUTTONS, 
  PAGE_ACTIVE_GROUP_BUTTON, 
  PAGE_CLEAR_PAGE,
  PAGE_ADD_ICON_BUTTONS,
  CLEAR_ICON_BUTTONS,
  PAGE_ADD_CUSTOM,
  PAGE_CLEAR_CUSTOM,
  PAGE_SET_URL
} from './PageConstants';

export const pageTitle = (title) => {
  return {
      type: PAGE_TITLE,
      title
  };
};

export const addGroupButtons = (buttons) => {
  return {
    type: PAGE_ADD_GROUP_BUTTONS,
    buttons
  }
}

export const addIconButtons = iconButtons => {
  return {
    type: PAGE_ADD_ICON_BUTTONS,
    iconButtons
  }
}

export const activeGroupButton = activeButton => {
  return {
    type: PAGE_ACTIVE_GROUP_BUTTON,
    activeButton
  }
}

export const clearIconButtons = () => {
  return {
    type: CLEAR_ICON_BUTTONS
  }
}

export const clearPage = () => {
  return {
    type: PAGE_CLEAR_PAGE
  }
}

export const addCustom = custom => {
  return {
    type: PAGE_ADD_CUSTOM,
    custom
  }
}

export const clearCustom = () => {
  return {
    type: PAGE_CLEAR_CUSTOM
  }
}

export const setUrl = url => {
  return {
    type: PAGE_SET_URL,
    url
  }
}