import React from 'react';
import useKeyboard from '../Keyboard';
import {apis} from 'components/Bitly/index.tsx';
import useApi from '@bit/hlouzek.rhplus.api';

export function useValueReader() {
	const { callApi, errorCatch } = useApi(process.env.REACT_APP_PROXY);
	const { CheckKeyboard } = useKeyboard();

  const readValue = value => {
				var promise = new Promise((resolve, reject) => {
			if (value.indexOf("bit.ly") > -1) {
				callApi({...apis.bitLy, paramObject: {bitLyId: value}})
					.then(response => response.data.bitLy)
					.then(response => {
						if (!!response.value) {
							resolve(response.value);
						} else {
							reject();
						}
					})
			} else {
				const response = CheckKeyboard(value);
				resolve(response);
			}
		})
		return promise;
  }

  return {
    readValue
  }
}
