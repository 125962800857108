import {
  FETCH_ALL_SUMMARY_DATA,
  UPDATE_SUMMARY,
  UPDATE_GROUP_PAGES,
  COMPLETE_DROPBOX_FILES
} from './summaryConsts';

export const fetchSummaryData = data => {
  return {
    type: FETCH_ALL_SUMMARY_DATA,
    data
  }
}

export const updateSummary = item => {
  return {
    type: UPDATE_SUMMARY,
    item
  }
}

export const updateGroupPages = (items, isGroupPage, groupPageId, groupPageName) => {
  return {
    type: UPDATE_GROUP_PAGES,
    items,
    isGroupPage,
    groupPageId,
    groupPageName
  }
}

export const completeDropboxFiles = folders => {
  return {
    type: COMPLETE_DROPBOX_FILES,
    folders
  }
}