import { toast } from 'react-toastify';

export const errorCatch = (error) => {
  console.log(error.response);
  toast.error("API error, see console for details.");
}

export const successMessage = (message) => {
  toast.success(message);
}

const headers = {
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT"
}

const palletDetail = {
  url: "api/stockout/palletdetail",
  methodType: "POST",
  headers,
  version: "1.0"
}

const projects = {
  url: "api/stockout/projects",
  methodType: "POST",
  headers,
  version: "1.0"
}

const outPalletSummary = {
  url: "api/stockout/outpalletsummary",
  methodType: "POST",
  paramObject: { active: false},
  headers,
  version: "1.0"
}

const updateOutPallet = {
  url: "api/stockout/updateoutpallet",
  methodType: "POST",
  headers,
  version: "1.0"
}

const deliveryNoteReport = {
  url: "api/report/deliverynotereport",
  methodType: "POST",
  responseType: "arraybuffer",
  headers,
  version: "1.0"
}

const printDeliverySticker = {
  url: "api/report/printdeliverysticker",
  methodType: "POST",
  headers,
  version: "1.0"
}

const cmrReport = {
  url: "api/report/cmrreport",
  methodType: "POST",
  responseType: "arraybuffer",
  headers,
  version: "1.0"
}

const stockoutPositionDetailReport = {
  url: "api/report/stockoutpalletdetail",
  methodType: "POST",
  responseType: "arraybuffer",
  headers,
  version: "1.0"
}

const stockoutPositionDetailReportPrint = {
  url: "api/report/stockoutpalletdetailprint",
  methodType: "POST",
  headers,
  version: "1.0"
}

const uploadPhoto = {
  url: 'api/filestorage/uploadphoto',
  methodType: 'PUT',
  // headers,
  version: "1.0"
}

const thumbnails = {
  url: 'api/filestorage/thumbnails',
  methodType: 'POST',
  headers,
  version: "1.0"
}

const image = {
  url: 'api/filestorage/image',
  methodType: 'POST',
  headers,
  version: "1.0"
}

const getReturnOutBox = {
  url: 'api/stockout/getReturnOutBox',
  methodType: 'POST',
  headers,
  version: "1.0"
}

const returnOutBox = {
  url: 'api/stockout/returnOutBox',
  methodType: 'POST',
  headers,
  version: "1.0"
}

const groupPages = {
  url: 'api/stockout/groupPages',
  methodType: 'POST',
  headers,
  version: "1.0"
}

const updateGroupPageFromPosition = {
  url: 'api/stockout/updateGroupPageFromPosition',
  methodType: 'POST',
  headers,
  version: "1.0"
}

export const apis = {
  palletDetail,
  projects,
  outPalletSummary,
  updateOutPallet,
  stockoutPositionDetailReport,
  stockoutPositionDetailReportPrint,
  uploadPhoto,
  thumbnails,
  image,
  deliveryNoteReport,
  cmrReport,
  printDeliverySticker,
  getReturnOutBox,
  returnOutBox,
  groupPages,
  updateGroupPageFromPosition
}
