"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useValueReader;

var _hlouzekRhplus = _interopRequireDefault(require("@bit/hlouzek.rhplus.keyboard"));

var _hlouzekRhplus2 = require("@bit/hlouzek.rhplus.bitly");

var _axios = _interopRequireDefault(require("axios"));

var _hlouzekRhplus3 = _interopRequireDefault(require("@bit/hlouzek.rhplus.api"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function useValueReader() {
  var _useKeyboard = (0, _hlouzekRhplus.default)(),
      checkKeyboard = _useKeyboard.checkKeyboard,
      validBarcode = _useKeyboard.validBarcode;

  var _useApi = (0, _hlouzekRhplus3.default)(process.env.REACT_APP_PROXY),
      callApi = _useApi.callApi,
      errorCatch = _useApi.errorCatch;

  var readValue = function readValue(input) {
    var value = checkKeyboard(input);
    var promise = new Promise(function (resolve, reject) {
      if (value.indexOf("bit.ly") > -1) {
        callApi(_objectSpread(_objectSpread({}, _hlouzekRhplus2.apis.bitLy), {}, {
          paramObject: {
            bitLyId: value
          }
        })).then(function (response) {
          return response.data.bitLy;
        }).then(function (response) {
          if (!!response.value) {
            resolve(response.value);
          } else {
            reject();
          }
        }).catch(function (error) {
          return reject(error);
        });
      } else {
        if (validBarcode(value)) {
          resolve(value);
        } else reject("Invalid barcode");
      }
    });
    return promise;
  };

  return {
    readValue: readValue
  };
}

