import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';


class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
            menuLi[j].classList.remove('open')
          }
        }
        this.classList.toggle('open');
      }
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  componentWillReceiveProps(nextProps) {

    const { history } = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">

          <li className="nav-header">
            <IntlMessages id="sidebar.modules" />
          </li>

          <li className="menu no-arrow">

          </li>

          {/* <li className="menu no-arrow">
            <a href="https://companypoint.rhplus.cloud/wms/stockamount">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Sklad</span>
            </a>
          </li>

          <li className="menu no-arrow">
            <a href="https://companypoint.rhplus.cloud/dashboard">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </a>
          </li>

          <li className="menu no-arrow">
            <a href="https://companypoint.rhplus.cloud/wms/stockin">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Přijem</span>
            </a>
          </li>

          <li className="menu no-arrow">
            <a href="https://companypoint.rhplus.cloud/wms/stockout">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Výdej</span>
            </a>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/stockout">
              <i className="zmdi zmdi-receipt zmdi-hc-fw" />
              <span className="nav-text font-weight-bold">
                Vychystané palety
              </span>
            </NavLink>
          </li> */}

          {/* <li className="menu no-arrow">
            <a href="https://companypoint.rhplus.cloud/">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Stock Prediction</span>
            </a>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/quotes">
              <i className="zmdi zmdi-ungroup zmdi-hc-fw" />
              <span className="nav-text ">Phantom</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/quotes">
              <i className="zmdi zmdi-ungroup zmdi-hc-fw" />
              <span className="nav-text ">Revit Upload</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/quotes">
              <i className="zmdi zmdi-ungroup zmdi-hc-fw" />
              <span className="nav-text ">Quotes</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/quotes">
              <i className="zmdi zmdi-ungroup zmdi-hc-fw" />
              <span className="nav-text ">Final Account</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <a href="https://fuj01.rhplus.cloud/Orders">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Purchase Order</span>
            </a>
          </li>

          <li className="menu no-arrow">
            <a href="https://fuj01.rhplus.cloud/Containers">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Supplier Trucker</span>
            </a>
          </li>

          <li className="menu no-arrow">
            <a href="https://companypoint.rhplus.cloud/report/deliveryprojectsreport">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Delivery Schedule</span>
            </a>
          </li>

          <li className="menu no-arrow">
            <a href="https://companypoint.rhplus.cloud/Wms/Product/ProductSpecificationInventory">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Project</span>
            </a>
          </li>

          <li className="menu no-arrow">
            <a href="https://companypoint.rhplus.cloud/Wms/Product/ProductSpecificationInventory">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Assembly</span>
            </a>
          </li> */}

          <li className="menu no-arrow">
            <a href="https://companypoint.rhplus.cloud/Wms">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Wms</span>
            </a>
          </li>

{/* 
          <li className="menu no-arrow">
            <a href="https://companypoint.rhplus.cloud/wms/map">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Warehouse Map</span>
            </a>
          </li> */}
{/* 
          <li className="menu no-arrow">
            <a href="https://companypoint.rhplus.cloud/Map/Map">
              <i className="zmdi zmdi-railway zmdi-hc-fw" />
              <span className="nav-text">Map</span>
            </a>
          </li> */}

          {/*
      <li className="menu">
        <a role="button" href="javascript:void(0)">
          <i className="zmdi zmdi-library zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.modules.reports" />
          </span>
        </a>

        <ul className="sub-menu">
          <li>
            <NavLink className="prepend-icon" to="/app/reports/projectreport">
              <IntlMessages id="sidebar.reports.projectReports" />
            </NavLink>
          </li>
        </ul>
      </li>
      */}
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
