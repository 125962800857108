import * as React from 'react';
import moment from 'moment';
import {clientLogo, customFilter} from './ProjectRenderer';
import ImageRenderer from './ImageRenderer';

const textDateCutomComparer = (filter, value, filterText) => {
  if (value == null)
    return false;

  var _value = moment(value).format("DD.MM.YYYY HH:mm");

  debugger;
  switch (filter) {
    case 'contains':
      return _value.includes(filterText);
    case 'notContains':
      return !_value.includes(filterText);
    default:
      // should never happen
      console.warn('invalid filter type ' + filter);
      return false;
  }
}

export const options = {
  columnDefs: [
    {
      headerName: 'row', 
      field: '_rh_plus_ag_grid_row_index_desc', 
      width: 60,
      cellClass: ["text-center"],

    },
    {
      headerName: 'Nakládka?', field: 'planCollectionDate', width: 80, filter: "agTextColumnFilter",
      cellRenderer: data => data.value == null ? null : moment(data.value).format("DD.MM.YYYY"),
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains', 'notContains'],
        textCustomComparator: (filter, value, filterText) => textDateCutomComparer(filter, value, filterText)
      }
    },
    { headerName: 'Label', field: 'label', width: 100, filter: "agTextColumnFilter" },
    {
      headerName: 'Project',
      field: 'firstProjectName',
      width: 200,
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
        const client_storeNo = params.data.storeNo ? `(${params.data.storeNo})` : '';
        const { name } = clientLogo(params.data.clientName);
        return `${name} ${params.data.firstProjectName} ${client_storeNo}`
      },
      cellRenderer: 'projectRenderer', width: 200     
    },
    { headerName: 'Note', field: 'note', width: 210, filter: "agTextColumnFilter" },
    { headerName: 'Locations', field: 'fromLocationName', width: 100, filter: "agTextColumnFilter"},
    { headerName: 'Img', cellClass: ["text-center"], width: 50, field: 'storageFiles', cellRenderer: 'imageRenderer' },
    { headerName: 'GP', field: 'isGroupPage', cellClass: ["text-center"], width: 40,
      cellRenderer: (params) => {
        return params.value ? '✔️' : '';
      }
    },
    { headerName: 'Group Page Name', field: 'groupPageName', width: 170, filter: "agTextColumnFilter"},
    // { headerName: 'Positions', field: 'fromPositionLabel', width: 100, filter: "agTextColumnFilter"},
    { headerName: 'H', field: 'height', width: 50 },
    { headerName: 'W', field: 'width', width: 50 },
    { headerName: 'L', field: 'depth', width: 50 },
    { headerName: 'kg', field: 'weight', width: 50 },
    {
      headerName: 'Picked', field: 'picked', width: 110,
      cellRenderer: (data) => data.value == null ? null : moment(data.value).format("DD.MM.YYYY HH:mm"),
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains', 'notContains'],
        textCustomComparator: (filter, value, filterText) => textDateCutomComparer(filter, value, filterText)
      },
    },
    {
      headerName: 'Collected', field: 'realizeCollectionDate', width: 110, filter: "agTextColumnFilter",
      cellRenderer: data => data.value == null ? null : moment(data.value).format("DD.MM.YYYY HH:mm")
    },
    { headerName: 'Store Type', field: 'storeTypeName', width: 140, filter: "agTextColumnFilter" },
    { headerName: 'Delivery', field: 'deliveryLabel', width: 160, filter: "agTextColumnFilter" },
    { headerName: 'Registration', field: 'registrationNumber', width: 160, filter: "agTextColumnFilter" },
    { headerName: 'Department', field: 'departmentName', width: 140, filter: "agTextColumnFilter" },
    { headerName: 'Projects', field: 'projectName', width: 210, filter: "agTextColumnFilter" },
    // { headerName: 'Sub', field: 'subdepartmentName', width: 130, filter: "agTextColumnFilter" },
    // { headerName: 'Delivery Address', field: 'deliveryAddress', width: 250, filter: "agTextColumnFilter"},
    {
      headerName: 'Expected Weight (kg)', field: 'expectedWeight', width: 100,
      cellRenderer: ({ value }) => parseFloat(value).toFixed(2)
    },
    { headerName: 'Manual Weight (kg)', field: 'weight', width: 100 },
    { headerName: 'Difference (kg)', field: 'differenceWeight', width: 100 },
    { headerName: 'Document', field: 'documentNumber', width: 140, filter: "agTextColumnFilter" },
    { headerName: 'Ean', field: 'ean', width: 100, filter: "agTextColumnFilter" },
    { headerName: 'Photos', field: 'files.filesCount', width: 90, filter: "agTextColumnFilter" },
    {
      headerName: 'Canceled', field: 'cancelDate', width: 110, filter: "agTextColumnFilter",
      cellRenderer: (data) => data.value == null ? null : moment(data.value).format("DD.MM.YYYY"),
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains', 'notContains'],
        textCustomComparator: (filter, value, filterText) => textDateCutomComparer(filter, value, filterText)
      }
    },
    { 
      headerName: 'Picked', 
      field: 'existDocumentItemOk', 
      width: 100, 
      filter: "agTextColumnFilter",
      valueGetter: ({data}) => {
        if (!data.forwarded || data.ExistDocumentItemOk) 
          return "Ready";
        else
          return "On the way";
      }
    }
  ],
  rowClassRules: {
    'table-on-the-way': 'data.forwarded',
    'table-color-green': 'data.realizeCollectionDate != null',
    'table-color-orange': 'data.realizeCollectionDate == null && (data.weight == null || data.width == null || data.height == null || data.depth == null)',
    'table-color-red': 'data.cancelDate != null || (data.realizeCollectionDate == null && data.weight == null && data.width == null && data.height == null && data.depth == null)',
  },
  defaultColDef: {
    resizable: true,
    sortable: true,
    filter: false,
    filterParams: {
      defaultOption: "contains"
    },
    enableCellChangeFlash: true,
  }
}
