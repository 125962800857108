import React from 'react';
import { useValueReader } from '../ValueReader';

let prevPickInfo = {};

export default function Scanner({ handleScanner }) {
const { readValue } = useValueReader();

	const handleClick = () => {
		const scanner = document.getElementById("scanner").value;
		const {time, value} = prevPickInfo;

		prevPickInfo = {
			time: new Date(),
			value: scanner
		}

		if (time != undefined) {
			var dif = new Date().getTime() - time.getTime();
			if (value === scanner && dif < 1000) {
				return;
			}
		}

		readValue(scanner)
			.then(code => {
				handleScanner({code});
			})
	}

	return (
		<div>
			<input id="scanner" type="hidden" onClick={handleClick} />
		</div>
	);
}