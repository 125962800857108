import { isIfStatement } from 'typescript';
import {
  FETCH_ALL_SUMMARY_DATA,
  UPDATE_SUMMARY,
  UPDATE_GROUP_PAGES,
  COMPLETE_DROPBOX_FILES
} from './summaryConsts';
import Enumerable from 'linq';

export const initSummary = {
  items: []
}

export default function summaryReducer(summaryState = initSummary, action) {
  switch (action.type) {

    case FETCH_ALL_SUMMARY_DATA:
      const ordering = Enumerable.from(action.data.items)
      .orderBy(o => !o.cancelDate ? 1 : 2)
      .thenBy(o => {
        if (o.cancelDate != null || (o.realizeCollectionDate == null && o.weight == null && o.width == null && o.height == null && o.depth == null))
          return 1;
        else if (o.realizeCollectionDate != null)
          return 3;
        else 
          return 2;
      })
      .thenByDescending(o => o.picked)      
      .toArray();

      return {
        items: ordering
      }
    
    case UPDATE_SUMMARY:
      const {ean, width, height, depth, weight, planCollectionDate, realizeCollectionDate} = action.item;
      return {
        items: summaryState.items.map(data => {
          if (data.ean === ean) {
            return {
              ...data,
                width,
                height,
                depth,
                weight,
                planCollectionDate,
                realizeCollectionDate
            }
          } else {
            return data;
          }
        })
      }

    case UPDATE_GROUP_PAGES:
      return {
        items: Enumerable.from(summaryState.items)
                 .groupJoin(Enumerable.from(action.items), 
                    pk => pk.ean,
                    fk => fk.ean,
                    (left, right) => ({...left, right}))
                    .selectMany(m => m.right.defaultIfEmpty(),
                         (left, right) => ({
                          left, 
                          right
                        }))
                    .select(s => {
                      if (s.right) {
                        return {
                          ...s.left, 
                          isGroupPage: action.isGroupPage,
                          groupPageId: action.groupPageId,
                          groupPageName: action.groupPageName
                        }
                      }
                      return s.left;
                    })
                    .toArray()
      }
    case COMPLETE_DROPBOX_FILES:
      var result = 
        Enumerable.from(summaryState.items)
          .groupJoin(Enumerable.from(action.folders),
              left => left.label,
              right => right.folder.name,
              (left, right) => ({ 
                item: {
                  ...left, 
                  files: right.defaultIfEmpty(
                    {
                      filesCount: 0,
                      files: []
                    }
                  ).firstOrDefault()
                }
              })
        )
        .toArray();
      var response = {
        ...summaryState,
        items: result.map(record => ({
          ...record.item
        }))
      }
      return response;
      

    default: 
      return summaryState;
  }
}