import React, {Component} from 'react';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import "assets/vendors/style"
import indigoTheme from './themes/indigoTheme';
import cyanTheme from './themes/cyanTheme';
import orangeTheme from './themes/orangeTheme';
import amberTheme from './themes/amberTheme';
import pinkTheme from './themes/pinkTheme';
import blueTheme from './themes/blueTheme';
import purpleTheme from './themes/purpleTheme';
import greenTheme from './themes/greenTheme';
import darkTheme from './themes/darkTheme';
import AppLocale from '../lngProvider';
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from 'constants/ThemeColors';

import MainApp from 'app/index';
import SignIn from './SignIn';
import SignUp from './SignUp';
import {setInitUrl} from '../actions/Auth';
import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import DateFnsUtils from '@date-io/date-fns'

const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      <Component {...props} />}
    
      // authUser
      //   ? <Component {...props} />
      //   : <Redirect
      //     to={{
      //       pathname: '/signin',
      //       state: {from: props.location}
      //     }}
      //   />}
  />;

class App extends Component {

  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

    //TODO: projit a opravit
    // if (this.props.initURL === '') {
    //   this.props.setInitUrl(this.props.history.location.pathname);
    // }
  }

  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createTheme(indigoTheme);
        break;
      }
      case DARK_CYAN: {
        applyTheme = createTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createTheme(greenTheme);
        break;
      }
      default : createTheme(indigoTheme);
    }
    return applyTheme;
  }

  render() {
    const {match, location, themeColor, locale, authUser, initURL, isDirectionRTL, isDarkTheme} = this.props;
    if (location.pathname === '/') {
      // if (authUser === null) {
      //   return ( <Redirect to={'/signin'}/> );
      // } else 
      if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return ( <Redirect to={'/app'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }
    
    let applyTheme = createTheme(indigoTheme);
    if (isDarkTheme) {
      document.body.classList.add('dark-theme');
      applyTheme = createTheme(darkTheme)
    } else {
      applyTheme = this.getColorTheme(themeColor, applyTheme);
    }
    
    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main" /*style={{minWidth: 800}}*/>
                <Switch>
                  <RestrictedRoute path={`${match.url}app`} authUser={authUser}
                                   component={MainApp}/>
                  <Route path='/signin' component={SignIn}/>
                  <Route path='/signup' component={SignUp}/>
                  <Route component={asyncComponent(() => import('components/Error404'))}/>
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {themeColor, sideNavColor, darkTheme, locale, isDirectionRTL} = settings;
  const {authUser, initURL} = auth;
  return {themeColor, sideNavColor, isDarkTheme: darkTheme, locale, isDirectionRTL, authUser, initURL}
};

export default connect(mapStateToProps, {setInitUrl})(App);

