import React from 'react';
import CacheRoute, {CacheSwitch} from 'react-router-cache-route';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Summary from './routes/Summary';
import asyncComponent from 'util/asyncComponent';

const StockOut = ({match}) => (
    <CacheSwitch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/summary`} />
        <CacheRoute path={`${match.url}/summary`} component={Summary} exact />
        <Route path={`${match.url}/detail/:ean`} component={asyncComponent(() => import('./routes/Detail'))} exact />
        <Route path={`${match.url}/takephoto/:ean`} component={asyncComponent(() => import('./routes/TakePhoto'))} exact />
    </CacheSwitch>
);

export default StockOut;
