import React, { useEffect, useState, useRef, useReducer } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import AgGrid from "@bit/hlouzek.rhplus.ag-grid";
import AgGridHeight from "@bit/hlouzek.rhplus.ag-grid-height";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { options } from "../Shared/SummaryGridOptions";
import useApi from "@bit/hlouzek.rhplus.api";
import { apis } from "api/apiService";
import "antd/dist/antd.css";
import {DownloadOutlined} from '@ant-design/icons';
import Camera from "components/Camera";
import BarcodeInput from "components/BarcodeInput";
import Scanner from "components/Scanner";
import { UseScannerDetection } from "@bit/hlouzek.rhplus.barcodescanner/index";
import { useSummaryContext } from "../redux/summaryContext";
import summaryReducer, { initSummary } from "../redux/summaryReducer";
import {
  fetchSummaryData,
  updateGroupPages,
  completeDropboxFiles,
} from "../redux/summaryActions";
import {
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import MathCalculation from "components/ag-grid/MathCalculation";
import Enumerable from "linq";
import usePage from "components/Page/usePage";
import "@bit/hlouzek.rhplus.ag-grid-styles/AgGridStyles/ag-theme-radim.scss";
import Pusher, {
  PusherProvider,
} from "@bit/hlouzek.rhplus.pusher/Pusher/index";
import moment from 'moment';
import ProjectRenderer from '../Shared/ProjectRenderer';
import ImageRenderer from "../Shared/ImageRenderer";
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import "./style.css";

const pusherConfig = require(`${process.env.REACT_APP_PUSHER}`);

const filterYears = () => {
  return [
    {
      value: 0,
      label: 'Picked pallets'
    },
    {
      value: 1,
      label: 'Forwarded pallets'
    },
    ...Enumerable.range(moment().year() - 5, 6)
      .orderByDescending(o => o)
      .select(s => ({
        value: s,
        label: s
      }))
      .toArray(),
    {
      value: -1,
      label: 'All'
    }
  ]
}


export function Items(props) {
  const { callApi, errorCatch } = useApi(process.env.REACT_APP_PROXY);
  const { outPalletSummary, groupPages, updateGroupPageFromPosition, image } = apis;
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(true);
  const { summaryState, summaryDispatch } = useSummaryContext();
  const [gridApi, setGridApi] = useState(null);
  const [allPallets, setAllPallets] = useState(false);
  const [messageInfoOpen, setMessageInfoOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});
  const { computeGridHeight } = AgGridHeight();
  let wrapper = React.useRef();
  const { addCustom, clearCustom } = usePage(props.match);
  const [year, setYear] = React.useState(0);
  const [groupPageList, setGroupPageList] = React.useState([]);
  const gridRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [lightboxImage, setLightboxImage] = React.useState();
  const [storageFiles, setStorageFiles] = React.useState([]);
  const [rowIndex, setRowIndex] = React.useState(0);
  const [imageTitle, setImageTitle] = React.useState('');
  const [imageCaption, setImageCaption] = React.useState('');

  const rowData = summaryState.items;

  // useEffect(() => {
  //     addCustom(filter());

  //     return () => clearCustom();
  // }, [loading, allPallets]);

  const filter = () => (
    <div className="mt-2">
      {/* <FormControlLabel
        style={{ marginTop: "-10px" }}
        control={FilterSwitch}
        label="All"
        labelPlacement="start"
        disabled={loading}
      /> */}
      <FormControlLabel
        style={{ marginTop: "-10px", marginRight: "10px" }}
        control={YearSelect}
        label="Filter"
        labelPlacement="start"
        disabled={false}
      />
    </div>
  );

  const filterByPicked = () => {
    if (gridApi) {
      gridApi.setFilterModel({
        existDocumentItemOk: {
          type: 'equals',
          filter: "Ready" // hodnota, podle které chcete filtrovat
        }
      });
    }
  };

  React.useLayoutEffect(() => {
    computeGridHeight(wrapper, 10);
  }, [, loading]);

  useEffect(() => {
    loadData();
    loadContextMenu();
  }, [allPallets, year]);

  const loadData = async (id) => {
    setLoading(true);
    try {
      const response = await callApi({
        ...outPalletSummary,
        paramObject: { allPallets: year === -1 ? true : false, year, id },
      });
      let data = response.data;
      if (year === 1)
        data.items = data.items.filter(f => !f.existDocumentItemOk);

      summaryDispatch(fetchSummaryData(data));
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const loadContextMenu = async () => {
    if (groupPageList && groupPageList.length > 0)
      return groupPageList;

    try {
      const response = await callApi({ ...groupPages });
      setGroupPageList(response.data.items);
      return response.data.items;
    } catch { }
  }

  useEffect(() => {
    if (year === 0) /* picked pallets */
      filterByPicked();

  }, [gridApi, year])

  useEffect(() => {
    computeGridHeight(0);
  }, []);

  const onCellDoubleClicked = (params) => {
    const { column: { colId } = {}, data: { ean } = {} } = params;

    if (colId === "storageFiles") {
      handleShowPhotos(params);
    } else {
      props.history.push(`/app/stockout/detail/${ean}`);
    }
  };

  const generateImageCaptionRows = params => {
    const {
        cancelDate,
        created,
        picked,
        deliveryAddress,
        departmentName,
        width, height, depth, weight, expectedWeight, differenceWeight,
        documentNumber,
        fromLocationName, 
        fromPositionLabel,
        groupPageName,
        note,
        planCollectionDate,
        realizeCollectionDate,
        realizeDate,
        registrationNumber,
        subdepartmentName
    } = params;

    const data = {
      ['Cancel Date']: {
        show: !!cancelDate,
        caption: cancelDate && moment(cancelDate).format("YYYY.MM.DD HH:mm")
      },
      Picked: {
        show: !!picked, 
        caption: picked && moment(picked).format("YYYY.MM.DD HH:mm")
      },
      ['Delivery Address']: {
        show: !!deliveryAddress,
        caption: deliveryAddress
      },
      ['Department Name']: {
        show: !!departmentName,
        caption: departmentName
      },
      ['Subdepartment Name']: {
        show: !!subdepartmentName,
        caption: subdepartmentName
      },
      Size: {
        show: !!width && !!height && !depth,
        caption: `W: ${Math.round(width)}, H: ${Math.round(height)}, L: ${Math.round(depth)}, Weight: ${Math.round(weight)}, Exp. weight: ${Math.round(expectedWeight)}, Diff: ${Math.round(differenceWeight)}`
      },
      ['Document number']: {
        show: !!documentNumber,
        caption: documentNumber
      },
      ['From Location']: {
        show: !!fromLocationName,
        caption: `${fromLocationName}, ${fromPositionLabel}`
      },
      ['Group Page']: {
        show: !!groupPageName,
        caption: groupPageName,
      },
      Note: {
        show: !!note,
        caption: note
      },
      ['Plan collection']: {
        show: !!planCollectionDate,
        caption: planCollectionDate && moment(planCollectionDate).format("YYYY.MM.DD")
      },
      ['Realize collection']: {
        show: !!realizeCollectionDate,
        caption: realizeCollectionDate && moment(realizeCollectionDate).format("YYYY.MM.DD")
      },
      ['Realize date']: {
        show: !!realizeDate,
        caption: realizeDate && moment(realizeDate).format("YYYY.MM.DD")
      },
      ['Registration Number']: {
        show: !!registrationNumber,
        caption: registrationNumber
      }
    }

      return Object.entries(data).map(([key, value]) => {
        const {show, caption} = value;
        if (show) {
          return (
            <tr key={key}>
              <td>{key}:</td>
              <td>{caption}</td>
            </tr>
          );
        }
        return null;
      });
  }

  const loadImage = (params, key) => {
    const {firstProjectName, clientName, label, storeNo, storeTypeName} = params;
    const title = `${clientName} (${storeNo}) - ${firstProjectName}, ${label}, ${storeTypeName}`;
    setImageTitle(title);

    const caption = 
      <table>
       {generateImageCaptionRows(params)}
      </table>

    setImageCaption(caption);
    setLightboxImage(null);
    callApi({ ...image, paramObject: { ImageKey: key } }).then(
      (image) => {
        const { data: {data} } = image;
        setLightboxImage(data);
      }
    );
  }

  const handleShowPhotos = params => {
    if (params.data.storageFiles && params.data.storageFiles.length > 0) {
      setRowIndex(params.node.rowIndex);
      setStorageFiles(params.data.storageFiles);
      setPhotoIndex(0);
      setIsOpen(true)
      loadImage(params.data, params.data.storageFiles[0].key);
    }
  }

  const handleMoveNextPhoto = React.useCallback(() => {
    let nextIndex = photoIndex + 1;
    let nextRow = rowIndex;

    if (nextIndex >= storageFiles.length) {
      nextRow++;
      if (nextRow < rowData.length) {
        nextIndex = 0;
        setStorageFiles(rowData[nextRow].storageFiles);
        setRowIndex(nextRow);
      } else {
        nextRow = 0;
        nextIndex = 0;
        setStorageFiles(rowData[nextRow].storageFiles);
        setRowIndex(nextRow);
      }
    }

    console.log("🚀 ~ handleMoveNextPhoto ~ rowData[nextRow]:", rowData[nextRow])
    setPhotoIndex(nextIndex);
    if (rowData[nextRow].storageFiles[nextIndex]) 
      loadImage(rowData[nextRow], rowData[nextRow].storageFiles[nextIndex].key);
  }, [photoIndex, storageFiles, rowIndex, rowData])


  const handleMovePrevPhoto = React.useCallback(() => {
    let prevIndex = photoIndex - 1;
    let prevRow = rowIndex;
    
    if (prevIndex < 0) {
      prevRow--;
      if (prevRow >= 0) {
        prevIndex = rowData[prevRow].storageFiles.length - 1;
        setStorageFiles(rowData[prevRow].storageFiles);
        setRowIndex(prevRow);
      } else {
        prevRow = rowData.length - 1;
        prevIndex = rowData[prevRow].storageFiles.length - 1;
        setStorageFiles(rowData[prevRow].storageFiles);
        setRowIndex(prevRow);
      }
    }

    setPhotoIndex(prevIndex);
    if (rowData[prevRow].storageFiles[prevIndex])
      loadImage(rowData[prevRow], rowData[prevRow].storageFiles[prevIndex].key);
  }, [photoIndex, storageFiles, rowIndex, rowData]);

  // const handleFilterChange = checked => {
  //     setChecked(checked);
  //     console.log("change", checked);
  //     var codeFilterComponent = gridApi.getFilterInstance("occupied");
  //     codeFilterComponent.setModel({
  //     type: "contains",
  //     filter: checked
  //     });
  //     gridApi.onFilterChanged();
  //   }

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onError = (error) => {
    console.log("ERROR! ", error);
  };

  const onComplete = (data) => {
    const { code } = data;
    if (!code)
      return;
    props.history.push(`/app/stockout/detail/${code}`);
  };

  const getSelectedRows = (params) => {
    var cellRanges = params.api.rangeController.cellRanges;
    if (!cellRanges || cellRanges.length === 0) return [];

    const selectedRows = new Set();
    cellRanges.forEach(cellRange => {
      for (let rowIndex = cellRange.startRow.rowIndex; rowIndex <= cellRange.endRow.rowIndex; rowIndex++) {
        const rowNode = params.api.getDisplayedRowAtIndex(rowIndex);
        if (rowNode) {
          selectedRows.add(rowNode.data);
        }
      }
    });
    return selectedRows;
  }

  const removeGroupPage = async (params) => {
    const selectedRows = getSelectedRows(params);
    const eans = Array.from(selectedRows).map(row => row.ean);
    updateGroupPage(eans, false, null, null);
  };

  const addGroupPage = async (groupPage, params) => {
    const selectedRows = getSelectedRows(params);
    const eans = Array.from(selectedRows).map(row => row.ean);
    updateGroupPage(eans, true, groupPage.id, groupPage.name);
  };

  const updateGroupPage = async (eans, isGroupPage, groupPageId, groupPageName) => {
    try {
      const response = await callApi({ ...updateGroupPageFromPosition, paramObject: { eans, isGroupPage, groupPageId } });
      summaryDispatch(updateGroupPages(response.data.positions, isGroupPage, groupPageId, groupPageName));
    } catch { }
  }

  const groupPageSubMenu = (params) => groupPageList && groupPageList.map((groupPage) => ({
    name: groupPage.name,
    action: () => addGroupPage(groupPage, params)
  }))

  const getContextMenuItems = params => {

    let result =
      [
        ...params.defaultItems,
        {
          name: 'Odstranit sběrku',
          action: () => removeGroupPage(params)
        },
        {
          name: 'Sběrky',
          subMenu: [
            {
              name: 'Výchozí sběrka',
              action: () => addGroupPage(0, params)
            },
            ...groupPageSubMenu(params)
          ]
        }
      ];
    // const selectedCells = params.api.getCellRanges();

    // selectedCells.forEach(range => {
    //   const { startRow, endRow, columns } = range;
    //   for (let rowIndex = startRow.rowIndex; rowIndex <= endRow.rowIndex; rowIndex++) {
    //     let rowNode = params.api.getDisplayedRowAtIndex(rowIndex);
    //     columns.forEach(col => {
    //       let cellValue = params.api.getValue(col.getColId(), rowNode);
    //       if (cellValue !== true) allSberkaTrue = false;
    //       if (cellValue !== false) allSberkaFalse = false;
    //     });
    //   }
    // });
    return result;
  };

  const toggleSberka = value => {
    const selectedCells = gridApi.getCellRanges();
    selectedCells.forEach(range => {
      const { startRow, endRow, columns } = range;
      for (let rowIndex = startRow.rowIndex; rowIndex <= endRow.rowIndex; rowIndex++) {
        let rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);
        // columns.forEach(col => {
        //   rowNode.setDataValue(col.getColId(), value);
        // });
      }
    });
  };

  const changeAllPallets = (event) => {
    setAllPallets((prev) => !prev);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessageInfoOpen(false);
  };

  const handleYearChange = e => {
    const { target: { value } = {} } = e
    setYear(value);
  }

  const YearSelect = (
    <Select
      style={{ marginLeft: '20px' }}
      labelId="year-label"
      id="year-select"
      value={year}
      label="Year"
      onChange={handleYearChange}
    >
      {filterYears().map(({ value, label }) => (
        <MenuItem value={value}>{label}</MenuItem>
      ))}
    </Select>
  );

  const FilterSwitch = (
    <Switch
      value={allPallets}
      checked={allPallets}
      onChange={changeAllPallets}
      color="primary"
    />
  );

  const onEvent = (e) => {
    loadData(e.data[0].id);
  };

  UseScannerDetection({ onError, onComplete });

  const frameworkComponents = {
    projectRenderer: ProjectRenderer,
    imageRenderer: ImageRenderer
  }

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = lightboxImage;
    link.download = `${imageTitle}.jpg`; // Nebo použijte vhodný název souboru
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <PusherProvider config={pusherConfig}>
        <Pusher
          channelName={"WMS_CHANNEL"}
          eventName={"event"}
          onEvent={onEvent}
        />
        {/*<BarcodeInput handleSubmit={} showBarCode={false} showCamera={false} />*/}
        <Scanner handleScanner={onComplete} />
        {filter()}
        <div className="animated slideInLeftTiny animation-duration-3">
          <div ref={wrapper} className="ag-theme-balham">
            {loading && (
              <div className="loader-view h-100">
                <CircularProgress />
              </div>
            )}
            {!loading && (
              <AgGrid
                ref={gridRef}
                columnDefs={options.columnDefs}
                rowData={summaryState.items}
                defaultColDef={options.defaultColDef}
                floatingFilter={true}
                animateRows={true}
                rowClassRules={options.rowClassRules}
                gridOptions={options.gridOptions}
                onCellDoubleClicked={onCellDoubleClicked}
                onGridReady={onGridReady}
                getContextMenuItems={getContextMenuItems}
                enableRangeSelection={true}
                enableNotification={true}
                frameworkComponents={frameworkComponents}
              />
            )}
          </div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={lightboxImage}
            nextSrc={lightboxImage}
            prevSrc={lightboxImage}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={handleMovePrevPhoto}
            onMoveNextRequest={handleMoveNextPhoto}
            imageTitle={imageTitle}
            imageCaption={imageCaption}
            toolbarButtons={[
                <div key="download" onClick={downloadImage} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#b4b6b5', fontSize: '20px', marginRight: 5 }}>
                  <DownloadOutlined />
                </div>
            ]}
          />
        )}
      </PusherProvider>
    </div>
  );
}
